
.nFitScores {
    background-color: #FFF;

    min-height: 200px;
  }
  
  .nFitScores .controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .nFitScores .controls .nFitScoreTitle {
    font-family: Roboto;
    font-size:16px;
    font-weight: bold;
    padding:20px;
  }

  .nFitScores .controls button.timePeriod {
    margin: 12px 3px;
    padding: 4px;
    border: 1px solid var(--unnamed-color-4d4d4d);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #4D4D4D;
    border-radius: 10px;
    font-size: 9px;
    text-decoration: none;
    color:#4d4d4d;
    height: 20px;
  }
  
  .nFitScores .controls button.selected {
    background: #E2E8FC 0% 0% no-repeat padding-box;
  }

  .nFitScores .controls label {
    font-size: 9px;
    color: #1476BC;
  }

  .nFitScores  .controls .showScan {
    margin-left: 50px;
    padding: 10px 15px;
    background-color: rgb(167, 20, 20);
    color: white;
  }

  .manageScores {
    display: flex;
    flex-direction: row;
  }

  .overallScore {
    margin: 10px 20px;
  }

  .overallScoreLabel {
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
  }

  .numericOverallScore {
    font-size: 24px;
    font-weight: bold;
  }
  
  .numericOverallScore.up {
    color: #0E9648;
  }

  .numericOverallScore.down {
    color: #F0A06D;
  }

  .annotationForm {
    display: flex;
    flex-direction: column;
  }

  .annotationInfo, .annotationInfo .icon-medium {
    font-size: 10px;
    color: #0025C1;

    margin-bottom: 5px;
  }

  .annotationInfo, .annotationEntry {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px; /* Adjust the value to add space between the image and text */
    align-items: flex-start;
  }

  .annotationEntry textarea, .annotationEntry button {
    display: block;
    align-self: start;
  }

  .annotationEntry textarea {
    box-sizing: border-box;
    border: 2px solid #D6D6D6;
    border-radius: 4px;
    width: 413px;
    height: 57px;
    padding: 5px;
    font-size: 10px;
    
    resize: none;
  }

  .annotationEntry button .icon-medium {
    margin-top: 4px;
    margin-left: -60px;

    color: #000;
  }

  .qrCodeBorder {
    margin: 5px;
    padding: 10px;
  }