.group-user-control {
    display: flex;
    flex-direction: column;
}

.group-user-control h4 {
    font-size: 9px;
}

.group-user-control div {
    display: flex;
    flex-direction: row;
}

.group-user-list {
    display: flex;
    flex-direction: row;
}

.group-user-list button {
    font-size: 11px;
    text-decoration: none;
    text-align: center;
    line-height: 24px;

    border-radius: 50%;

    margin-right: 4px;
    width: 28px;
    height: 24px;
    
    border: 1px solid #0E9648;
    background-color: #ECFCF9;
    color: #0E9648;
}

.group-user-control button.selected {
    border: 2px solid #1376BC;
    color: #1376BC;
}

.user-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
}

.user-filter select {
    margin-right: 10px;
    border-color: #D6D6D6;
    height: 27px;
    padding: 6px;

    color: #4D4D4D;
    font-size: 10px;
    opacity: 0.5;
}

.user-filter button {
    margin-right: 6px;
    width: 70px;
    height: 20px;
    border: 1px solid #4D4D4d;
    border-radius: 10px;

    font-size: 10px;
}