body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
    
body {
  font-family: Poppins, sans-serif;
  margin: 20px;
  padding: 0;

  display: flex;
  justify-content: center;
}
  
.page {
  display: flex;
  flex-direction: column;
}
ion-icon {
  color: #1476BC;
}

section {
  display: grid;
  grid-template-columns: 196px 1fr;
  grid-gap: 0;
}

@media (min-width: 800px) {
  .content {
    width: 604px;
  }
}

@media (min-width: 1024px) {
  .content {
    width: 828px;
  }
}

@media (min-width: 1200px) {
  .content {
    width: 1004px;
  }
}

@media (min-width: 1600px) {
  .content {
    width: 1280px;
  }
}

.navigation {
  align-self: start;
  width: 196px;

  padding-top: 20px;
  box-shadow: inset 0 6px 6px -6px gray;
}

.navigation ul {
  margin-top: 0px;
  padding-left: 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navigation li {
  list-style: none;
  list-style-position: inside;
  width: 100%;

}

.navigation li a {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px; /* Adjust the value to add space between the image and text */
  align-items: center;

  padding-left: 15px;
  width: 100%;
  height: 40px;

  background-repeat: no-repeat;

  line-height: 40px;
  font-size: 0.9em;
  color: #1476BC;
  text-decoration: none;
}

.navigation .icon-large {
  width: 22px;
  height: 22px;
}

.navigation li a.active {
  background-color: #F2F8FC;
  font-weight: bold;
}

.content { 
  min-height: 480px;
  background-color: #F2F8FC;

  padding: 20px;

  box-shadow: inset 0 6px 6px -6px gray;
}

.content > .breadcrumb {
  color: #1476BC;
  font-size: 12px;
}

.content > .title {
  padding: 18px 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
}

.drop-shadow-content-box {
  padding: 6px 12px 12px;
  background-color: #FFFFFF;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
}
