.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  
  .modal-main {
    display: flex;
    position:fixed;
    background: white;
    height: auto;
    top: 50%;
    left :50%;
    transform: translate(-50%,-50%);
    align-items: center;
  }

  .modal-content {    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 5px;
}

  button.modal-close {
    padding: 10px 15px;
    background-color: rgb(167, 20, 20);
    color: white;
  }