
button {
  background-color: #FFFFFF;
  border:none;
  cursor: pointer;
}

.statCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-right: 20px;
  width: 200px;
}
  
.statCards .cardHelp {
  width: 16px;
  height: 16px;
}

.statCards .cardHelp span {
    display: block;
    width: 400px;
    word-break: "break-all",
}

.statCards .cardDetails {
  display: flex;
  flex-direction: column;

  padding: 5px 6px 12px;
}

.cardTitle {
  padding: 6px 3px;
  font-size: 12px;
}
  
.cardScore {
  padding: 6px 3px;
  font-size: 24px;
  font-weight: bold;
}

.cardDelta {
  font-size: 20px;
  font-weight: normal;
}

.cardDelta.up {
  color: #0E9648;
}

.cardDelta.down {
  color: #F0A06D;
}
