header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: Roboto;

  height: 55px;
}

header a.logo img {
  max-width: 127px;
  max-height: 28px
}

header .user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 55px;
  align-items: center;
}

.user .icon-large {
  width: 25px;
  height: 25px;
}

.accountAction { 
  margin: 0 20px 0 10px;

  text-decoration: none;
  font-size: 9px;
  color: #4D4D4D;
}
